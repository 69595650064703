import angular from 'angular';
import './stationsHomePage.less';
import template from './stationsHomePage.html';

import './station.service';
import '../shared/stationBubble/stationBubble';

if (MyGlobal.project.isMobile) {
    import('./stationsHomePage_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myStationsHomePage', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $timeout, helper, stationService, userService) {
            const searchPage = 1;
            $scope.tags = [];
            $scope.searchMixtape = {};
            $scope.MAX_TAGS = 1;

            $scope.doSearch = async ({ suggestAllTags } = {})=> {

                if ((!suggestAllTags && !$scope.tags?.length) || $scope.searchLoading) return;

                try {

                    $scope.searchLoading = true;
                    delete $scope.noSearchResults;
                    delete $scope.searchResults;

                    $scope.searchQuery = !suggestAllTags ? $scope.tags[0].name : undefined;

                    const { models } = await stationService.searchStations({
                        query: $scope.searchQuery,
                        suggestAllTags,
                        page: searchPage,
                        itemsPerPage: 5
                    });

                    if (models?.length) {
                        $scope.searchResults = models;
                    } else {
                        $scope.noSearchResults = true;
                    }

                } catch(e) {
                    helper.error(e);
                } finally {
                    delete $scope.searchLoading;
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.onTagsChanged = () => {
                delete $scope.searchMixtape.val;
                delete $scope.searchResults;
            };

            $scope.onMixtapeToggled = ()=> {
                if ($scope.searchMixtape.val) {
                    $scope.tags.length = 0;
                    $scope.doSearch({ suggestAllTags: true });
                } else {
                    delete $scope.searchResults;
                }
            };

            (async ()=> {
                try {
                    $scope.loading = true;

                    if (userService.isLoggedIn()) {
                        $scope.myStations = await stationService.getMyStations();
                    }

                    $scope.topStations = await stationService.getTopStations();
                } catch(e) {
                    helper.error(e);
                } finally {
                    delete $scope.loading;
                    try { $scope.$digest(); } catch(e) {}
                }
            })();
        }
    };
});
