import angular from 'angular';

import './stationBubble.less';

if (MyGlobal.project.isMobile) {
    import('./stationBubble_mobile.less');
}

import template from './stationBubble.html';
import roomAccessRequestPopupCtrl from "../../popups/roomAccessRequestPopup/roomAccessRequestPopup.html";

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myStationBubble', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            station: '='
        },
        controller($scope, popupService, userService, roomService, userRoomsService, helper) {

            const BUBBLE_SIZE_CLASSES = {
                MEDIUM: 'mediumBubble',
                BIG: 'bigBubble'
            };

            const { favoriters } = $scope.station;

            $scope.tagsStr = $scope.station.musicTags?.map(item=>item.name).join(', ');

            switch(true) {
                case favoriters > 10 && favoriters <= 20:
                    $scope.sizeClass = BUBBLE_SIZE_CLASSES.MEDIUM;
                    break;
                case favoriters > 20:
                    $scope.sizeClass = BUBBLE_SIZE_CLASSES.BIG;
                    break;
            }

            $scope.onClick = ($event) => {
                if (!$scope.inUserPrivateRooms && roomService.isPrivate($scope.station) && $scope.station.entranceFee) {
                    $event.preventDefault();
                    popupService.openPopup({
                        $event,
                        template: roomAccessRequestPopupCtrl,
                        controller: 'MyRoomAccessRequestPopupCtrl',
                        resolve: {
                            popupParams: {
                                room: $scope.station
                            }
                        }
                    })
                }
            }

            (async () => {
                try {
                    if (userService.isLoggedIn()) {
                        $scope.isFavorite = await userRoomsService.inUserFavorites($scope.station._id);
                        $scope.inUserPrivateRooms = await userRoomsService.inUserPrivateRooms($scope.station._id);
                    }
                } catch(e) {
                    helper.error(e);
                }
            })();
        }
    };
});
