import angular from 'angular';

import '../../shared/stationBubble/stationBubble';
import './stationsPopup.less';
import '../../stationsHomePage/station.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyStationsPopupCtrl', ($scope, $uibModalInstance, helper, stationService, $timeout, $stateParams) => {

    let currPage = 1;

    $scope.tags = $stateParams.tags || [];
    $scope.openedTag = $scope?.tags[0];

    $scope.close = () => {
        $uibModalInstance.dismiss();
    };

    const loadRooms = async ({ append } = {}) => {

        if ($scope.loading) return;

        try {
            $scope.loading = true;
            const searchQuery = $scope.tags[0].name;
            const { models } = await stationService.searchStations({
                query: searchQuery,
                page: currPage,
                itemsPerPage: 50
            });

            if (append) {
                $scope.stations = ($scope.stations || []).concat(models);
            } else {
                $scope.stations = models;
            }
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.doSearch = async ()=> {
        currPage = 1;
        return await loadRooms();
    };

    if ($scope.tags?.length) {
        (async () => {
            await loadRooms({
                append: false
            });
        })();
    }

});
